import React from "react"

import Layout from "../../../components/layout-static"
import Seo from "../../../components/seo"
import { StaticImage } from "gatsby-plugin-image"

const Page = () => {
  const metadata = {
    title: "Write A Review",
    heroH1: "Write A Review",
    heroH2: "When experience, integrity, and results matter.",
  }
  return (
    <Layout metadata={metadata}>
      <Seo title={metadata.title} />
      <div
        id="content"
        className="flex flex-wrap container mx-auto px-4 xl:px-0"
      >
        <div className="w-full pt-8 xl:pt-0">
          <div className="mb-16">
            <h2>Please Write a Review</h2>
            <p className="mb-8">
              Your feedback is extremely important to us and we greatly value
              your opinion. Please use Google to leave a review. Other places to
              leave a review are LinkedIn, Avvo, or Facebook.
            </p>
            <p className="mb-8">
              If you have been happy with our services please click on five
              stars and leave a review that would help others choose Bartlett
              Legal Group.
            </p>
          </div>
          <h2>How to leave a Google review</h2>
          <p className="mb-8">
            <a
              href="https://search.google.com/local/writereview?placeid=ChIJjypmcQDG54kRutzskanSspA"
              target="_blank"
              rel="noreferrer"
            >
              The easiest way to leave a Google Review is to click this link.
            </a>{" "}
            If this does not work for you, follow the directions below:
          </p>
          <h3>From a Desktop Computer</h3>
          <p className="mb-8">
            1) Go to <a href="https://google.com/">Google.com</a> and search for
            Bartlett Legal Group. You should see this result.
          </p>
          <div className="mb-8 text-left">
            <StaticImage
              quality="100"
              src="../../../images/blggoogle.jpg"
              formats={["auto", "webp", "avif"]}
              alt="Screenshot showing how to leave a review for Bartlett Legal Group on Google"
            />
          </div>
          <p className="mb-8">
            2) Click on the “write a review” button. You then need to click on a
            star. The right hand star leaves a five star review…
          </p>
          <p className="mb-8">
            3) <strong>Need an account?</strong> Making a Google account is
            quick and easy.
          </p>
          <ul className="list-disc list-outside ml-6 mb-8">
            <li>
              Go to{" "}
              <a href="https://accounts.google.com/signup?hl=en">
                https://accounts.google.com/signup?hl=en
              </a>
            </li>
            <li>
              Fill in the required fields. You can use your existing email or
              create a new Gmail email address. You can leave “mobile number”
              blank. Click on “next step.”
            </li>
          </ul>
          <p className="mb-8">4) Once complete, log into Google and leave a review by going to step 1</p>
          <h3>From a Mobile Device</h3>
          <p className="mb-8">1) Click on your Google app or go to <a href="https://google.com" target="_blank" rel="noreferrer">Google</a> and search for Bartlett Legal Group. You should see a map and Bartlett Legal Group, LLC on a blue background.</p>
          <p className="mb-8">2) Click on the down arrow for more about Bartlett Legal Group.</p>
          <div className="mb-8 text-left">
            <StaticImage
              quality="100"
              src="../../../images/mobile-review.jpg"
              formats={["auto", "webp", "avif"]}
              alt="Screenshot showing how to leave a Google review for Bartlett Legal Group on a mobile device"
            />
          </div>
          <p className="mb-8">3) Scroll down and click on More Google reviews</p>
          <div className="mb-8 text-left">
            <StaticImage
              quality="100"
              width="300"
              src="../../../images/IMG_0140-576x1024.jpg"
              formats={["auto", "webp", "avif"]}
              alt="Screenshot showing how to view more Google Reviews"
            />
          </div>
          <p className="mb-8">4) Scroll down to where it shows five blank stars and click on the appropriate star.  We sincerely hope we have earned five stars. If we have, please click on the star on the far right.</p>
          <p className="mb-8">5) In the area below the stars where is says “share details of your own experience at this place”, please write a review that would be helpful to potential clients looking for a good attorney.</p>
          <h3>Need a Google account? See #3 Need an Account above under desktop instructions above.</h3>
        </div>

      </div>
    </Layout>
  )
}

export default Page
